var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "SalaryItem" },
    [
      !["A01", "A07", "A06", "A08"].includes(_vm.typeNo)
        ? _c(
            "a-form-model-item",
            { attrs: { label: "添加方式" } },
            [
              _c(
                "a-radio-group",
                {
                  on: { change: _vm.typeChange },
                  model: {
                    value: _vm.form.defineMode,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "defineMode", $$v)
                    },
                    expression: "form.defineMode",
                  },
                },
                [
                  _vm.typeNo && _vm.typeNo.slice(0, 1) !== "B"
                    ? _c(
                        "a-radio",
                        {
                          attrs: { disabled: _vm.mode === "edit", value: "1" },
                        },
                        [_vm._v("内置薪资项")]
                      )
                    : _vm._e(),
                  _c(
                    "a-radio",
                    { attrs: { disabled: _vm.mode === "edit", value: "2" } },
                    [_vm._v("自定义薪资项")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "a-form-model-item",
        { attrs: { label: _vm.salaryName } },
        [
          _vm.typeNo === "A01" || _vm.form.defineMode === "2"
            ? _c("a-input", {
                staticClass: "line-input",
                attrs: { disabled: _vm.typeNo === "A01" },
                model: {
                  value: _vm.form.salaryItemName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "salaryItemName", $$v)
                  },
                  expression: "form.salaryItemName",
                },
              })
            : _c(
                "a-radio-group",
                {
                  on: { change: _vm.salaryChange },
                  model: {
                    value: _vm.form.salaryItemNo,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "salaryItemNo", $$v)
                    },
                    expression: "form.salaryItemNo",
                  },
                },
                _vm._l(_vm.unconfigItemsList, function (item) {
                  return _c(
                    "a-radio-button",
                    {
                      key: item.salaryItemNo,
                      attrs: {
                        disabled: !!item.isConfig || _vm.mode === "edit",
                        value: item.salaryItemNo,
                      },
                    },
                    [_vm._v(" " + _vm._s(item.salaryItemName) + " ")]
                  )
                }),
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }